import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { store, RootState } from '../store'
import { get } from '../../profit/api';
import { getRegDescriptor } from '../../profit';
import { LoadableItemStatus } from '../../app/types';
import { useSelector } from 'react-redux';
import { ApplicationUnit } from '../../profit/regs';
import { createIdSelector, createSelector } from 'redux-views';

export interface SelectorListItem {
    id: number | string
    caption: string
    text: string
    memo?: string
}

export interface SelectorData {
    status: LoadableItemStatus,
    list: SelectorListItem[],
    loaded?: string
}

export interface SelectorsList {
    [key: string]: SelectorData
}

export interface SelectorsState {
    list: SelectorsList
}

const initialState: SelectorsState = {
    list: {}
}

interface SelectorLoadingIndicators {[key: string]: boolean}

// Handle selectors loading
const loading: SelectorLoadingIndicators = {}

export const loadSelector = createAsyncThunk(
    'selectors/load',
    async (payload: string, thunkApi) => {

        // If already started loading, prevent duplicate loading
        if(loading[payload])
            return []
        else
            loading[payload] = true;

        // payload is db/registry
        const a = payload.split('/')
        const db = a[0]
        const reg = a[1] as ApplicationUnit;

        const state = thunkApi.getState() as any
        const selector = getRegDescriptor(reg).selector;
        const endpoint = selector.endpoint
        const response = await get(state, db + '/' + endpoint, {
            ...selector.queryParams, 
            _fetch: 'all',
            _agg: 'none',
        }); //TODO better pagination

        const ret: SelectorListItem[] = response.data.map((item: any) => {
            return {
                id: item[selector.idCol], 
                caption: item[selector.captionCol],
                text: item[selector.textCol],
                memo: selector.memoCol ? item[selector.memoCol] : undefined
            }
        })
        return ret
    }           
)

export const selectorsSlice = createSlice({
    name: 'selectors',
    initialState,
    reducers: {
        resetAll: (state, action) => {
            state.list = {}
        },
        resetSelector: (state, action) => {
            state.list[action.payload] = {...state.list[action.payload], status: 'invalid'}
            // console.log('resetting selector', action.payload)
            // const reg = action.payload
            // delete state.list[reg]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSelector.pending, (state, action) => {
                const path = action.meta.arg;
                if(state.list[path])
                    return;
                // console.log('SEL PENDING', path);
                state.list[path] = {status: 'loading', list: []};
            })
            .addCase(loadSelector.fulfilled, (state, action) => {
                const path = action.meta.arg;
                // console.log('SEL FULFILLED', path);
                loading[path] = false;
                state.list[path] = {status: 'idle', list: action.payload, loaded: (new Date()).toUTCString()};
            })
            .addCase(loadSelector.rejected, (state, action) => {
                const path = action.meta.arg;
                console.warn('SEL REJECTED', path);
                loading[path] = false;
                state.list[path] = {status: 'error', list: []};
            })
    }
});

export const { resetAll, resetSelector } = selectorsSlice.actions;

export const selectRegSelectors = (state: RootState) => state.selectors;

// const getPathIdProp = createIdSelector((path: string) => path);

// const getRegSelectorItems = createSelector(
//     [
//         (state: RootState) => state.selectors.list,
//         createIdSelector((path: string) => path) // TODO make function?
//     ],
//     (list, path) => {
//         console.log('from getRegSelectorItems', path, list[path]?.list.length || 0);
//         return list[path]?.list || []
//     }
// );

// export const selectRegSelectorItems = (path: string): SelectorListItem[] => {
//     console.log('selectRegSelectorItems', path);
//     return getRegSelectorItems(store.getState(), path);
// }

export const selectRegSelectorItems = (path: string): SelectorListItem[] => {
    return useSelector(
                (state: RootState) => state.selectors.list[path]?.list || []
    );
};

export const selectRegSelectorStatus = (path: string): LoadableItemStatus =>
    useSelector((state: RootState) => state.selectors.list[path]?.status || 'invalid')


export default selectorsSlice.reducer;
