import React from "react";
import { AppBar, Button, IconButton, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { FormattedMessage } from "react-intl";
import { ColorSchemeSelector } from "../../themes/ThemeSelector";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LanguageSelectorMenu } from "../LanguageList";
import { useScreenSize } from "../../../app/hooks";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { ENABLED_FUNCTIONS } from "../../../configs/appConfig";

const SmallMenu = () => {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    // const dispatch = useAppDispatch();
    // const locale = getLocale();

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (href: string) => {
        // dispatch(setLocalSetupItem({ k: SETUP_LOCALE, v: language }))
        navigate(href);
        setAnchorEl(null);
    };

    const menuItems = [
        {
            title: 'about',
            href: '/'
        },
        {
            title: 'prices',
            href: '/doc/prices'
        },
        {
            title: 'register',
            href: '/register'
        },
        {
            title: 'btn_login',
            href: '/login'
        },
        {
            title: 'doc_privacy_title',
            href: '/doc/privacy'
        },
        {
            title: 'doc_terms_of_service_title',
            href: '/doc/terms_of_service'
        },
        // {
        //     title: 'doc_contact_title',
        //     href: '/doc/contact'
        // }
    ];

    return <>
        <Tooltip title={<FormattedMessage id='hint_menu' defaultMessage='Menu' />}>
            <IconButton
                id="menu"
                aria-haspopup="listbox"
                aria-controls="profit-menu"
                aria-label="select from menu"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClickListItem}
                sx={{ color: 'white' }}
            >
                {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
        </Tooltip>
        <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'language-selector',
                role: 'listbox',
            }}
        >{
                menuItems.map((item, index) => <MenuItem key={index} onClick={(event) => handleMenuItemClick(item.href)}>
                    <FormattedMessage id={item.title} />
                </MenuItem>
                )}

        </Menu>
    </>
}

export default function LoginAppBar({
    backButton = false,
    title,
}: {
    backButton?: boolean,
    title?: string
}) {

    const { docName } = useParams();

    const screenSize = useScreenSize();

    const navigate = useNavigate();

    const go = (link: string) => {
        navigate(link);
    }

    const toolButtonSx = { textTransform: 'none' };

    return <>
        <AppBar position="fixed">
            <Toolbar variant="dense">
                {screenSize === 'large' ? <>
                    {
                        backButton ? <Tooltip title={<FormattedMessage id='btn_back' defaultMessage='Back' />}>
                            <IconButton onClick={() => navigate('/')} color="inherit" aria-label="back" sx={{ mr: 2, ml: 0 }}>
                                <ArrowBackOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                            : null
                    }
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, pl: 8 }}>
                        <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <FormattedMessage id='app_title' defaultMessage='Profit' />
                        </Link>
                        {title ? <>{' : '}<FormattedMessage id={title} /></> : null}
                    </Typography>
                    {ENABLED_FUNCTIONS.PRICING_PACKAGES &&
                        <Stack direction="row" spacing={2}>
                            <Button sx={toolButtonSx} color='inherit' onClick={() => go('/doc/prices')}><FormattedMessage id='prices' /></Button>
                        </Stack>
                    }
                    <Stack direction="row" spacing={2}>
                        <Button sx={toolButtonSx} color='inherit' onClick={() => go('/register')}><FormattedMessage id='register' /></Button>
                        <Button sx={toolButtonSx} color="inherit" onClick={() => go('/login')} variant='outlined'><FormattedMessage id='btn_login' /></Button>
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ ml: 8 }}>
                        <ColorSchemeSelector />
                        <LanguageSelectorMenu />
                    </Stack>
                </> :
                    <>
                        <SmallMenu />
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, pl: 8 }}>
                            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <FormattedMessage id='app_title' defaultMessage='Profit' />
                            </Link>
                        </Typography>
                        <LanguageSelectorMenu />
                    </>
                }

            </Toolbar>
        </AppBar>
        <Toolbar />
    </>
}