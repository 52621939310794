import { eefr_asset_group_selector, eefr_data_presentment_selector, 
    eefr_main_account_selector, eefr_related_party_selector, 
    eefr_change_type_selector, eefr_nace_selector, 
    eefr_classification_codes, eefr_report_type_selector 
} from './registries/eefr_classifiers/eefr_classifiers';

import { eefr_reports } from './registries/eefr_reports/eefr_reports';

export const eefr_regs = { 
    eefr_main_account_selector, eefr_data_presentment_selector, eefr_asset_group_selector,
    eefr_related_party_selector, eefr_change_type_selector, 
    eefr_nace_selector, eefr_report_type_selector,
    eefr_classification_codes, eefr_reports
};

export type EEFRApplicationUnit = 
    | 'eefr_main_account_selector' | 'eefr_data_presentment_selector'
    | 'eefr_asset_group_selector' | 'eefr_related_party_selector'
    | 'eefr_change_type_selector' | 'eefr_nace_selector' | 'eefr_report_type_selector'
    | 'eefr_classification_codes' | 'eefr_reports'
    ;