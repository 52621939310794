import { ApplicationUnitDescriptor } from '../app/types/ApplicationUnitDescriptor'
import { accounts_regs, AccountingApplicationUnit } from './accounting'
import { capital_assets_regs, CapitalAssetsApplicationUnit } from './capital_assets'
import { common_regs, CommonApplicationUnit } from './common'
import { purchases_regs, PurchasesApplicationUnit } from './purchases'
import { sales_regs, SalesApplicationUnit } from './sales/'
import { warehouse_regs, WarehouseApplicationUnit } from './warehouse'
import { eefr_regs, EEFRApplicationUnit } from './eefr'

export type ApplicationUnit = 
    AccountingApplicationUnit
    | CapitalAssetsApplicationUnit
    | CommonApplicationUnit
    | PurchasesApplicationUnit
    | SalesApplicationUnit
    | WarehouseApplicationUnit
    | EEFRApplicationUnit
    | 'userSetupVar' | 'userSetupVarLocallySaved' | 'report'         // TODO remove it somehow
    | undefined

export interface ApplicationUnitDescriptors {
    [key: string]: ApplicationUnitDescriptor
}

const regs: ApplicationUnitDescriptors = {
    ...accounts_regs,
    ...capital_assets_regs,
    ...common_regs,
    ...purchases_regs,
    ...sales_regs,
    ...warehouse_regs,
    ...eefr_regs,
}

export default regs;