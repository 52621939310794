import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApplicationUnit } from '../../profit/regs'
import { extractDocChild, PATH_DELIMITER } from '../../app/utils'
import { DatabaseDescriptor } from '../../app/types'

export interface SelectorDialogData {
    callback?: {
        docPath: string         // path to document 
        field: string           // field of document
    }
    title: string           // title of dialog
    selectorReg: ApplicationUnit     // registry to select from
    sharedSetup: any        // shared setup to use
    defaultValue?: number | string
    docPath: string
}

export interface SelectorsDialogsState {
    stack: SelectorDialogData[]
}

const initialState: SelectorsDialogsState = {
    stack: []
}

export const selectorDialogsSlice = createSlice({
    name: 'selectorDialogs',
    initialState,
    reducers: {
        resetDialogs: (state) => {
            state.stack = []
        },
        pushDialog: (state, action: PayloadAction<SelectorDialogData>) => {
            const pl = action.payload;
            console.log('pushing', pl)
            // if(!action.payload.docPath) {

            //     const gs = store.getState();

            //     let dv: number | string;
            //     if (pl.defaultValue) {
            //         dv = pl.defaultValue;
            //     } else {
            //         const obj = gs.docs.list[pl.callback.docPath]?.record;
            //         dv = extractDocChild(obj, pl.callback.field) || 'new';
            //     }

            //     action.payload.docPath = gs.databases.currentDatabase!.uri
            //         + PATH_DELIMITER + pl.selectorReg 
            //         + PATH_DELIMITER + dv;
            // }
            state.stack.push(action.payload)
        },
        popDialog: (state) => {
            console.log('poping', state)
            state.stack.pop()
        }
    }
});

export const { resetDialogs, pushDialog, popDialog } = selectorDialogsSlice.actions;

export const selectSelectorDialogsTop = (state: RootState) => state.selectorDialogs.stack[state.selectorDialogs.stack.length - 1];
export const selectSelectorDialogsCount = (state: RootState) => state.selectorDialogs.stack.length;

export const constructDisplayedDocPath = (dbUri: string, obj: any, field: string, selectorReg: ApplicationUnit, defaultValue: number | string | undefined = undefined) => {
    const dlgDocPath = dbUri 
            + PATH_DELIMITER + selectorReg 
            + PATH_DELIMITER + (defaultValue 
                ? defaultValue 
                : ((obj && extractDocChild(obj, field)) || 'new')
            );

    return dlgDocPath;

    // const dv = pl.defaultValue || extractDocChild(obj, pl.callback.field) || 'new';
    // return db.uri + PATH_DELIMITER + pl.selectorReg + PATH_DELIMITER + dv;
}

export default selectorDialogsSlice.reducer;
