export const isDevMode = process.env.NODE_ENV === 'development';

export const APPCENTRAL_URL = isDevMode ? 'http://localhost:8085/pwc/' : 'https://services.intellisoft.ee/pwc/'; 

export const APP_URLS = {
    FRONTPAGE: '/hello/',
    BILLING: '/billing/',
    LOGIN: '/login',
    TERMS: '/doc/terms_of_service',
    PRIVACY: '/doc/privacy',
    REGISTER: '/register',
}

// 'https://profit.intellisoft.ee/api';

export const ENABLED_FUNCTIONS = {
    NEW_DATABASE: isDevMode,
    BILLING_UI: isDevMode,
    PRICING_PACKAGES: isDevMode,
    FRONTPAGE_DASHBOARD_DUE_INVOICES: isDevMode,
    FRONTPAGE_DASHBOARD_PROBLEMS: isDevMode,
    ENABLED_MENU_MODULES: isDevMode 
        ? ['sales', 'purchases', 'accounting', 'warehouse', 'capital_assets', 'state_io', 'common']
        : ['sales', 'purchases', 'common'],
}