import React from "react";
import { DocGridColumn, KeyValueList, RegistryDescriptor } from "../../../../app/types";
import { useIntl } from "react-intl";
import { getFromCurrentDB } from "../../../api";
import { store } from "../../../../redux/store";
import { INTL_DATEFORMAT } from "../../../../app/const";
import * as Yup from 'yup';
import { round2 } from "../../../../app/utils";
import * as validators from "../../../../app/validationUtils";

import { ApplicationUnit } from "../../../regs";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";

const EEFRReportEdit = React.lazy(() => import('./EEFRReportEdit'));

class EEFRReportRD extends RegistryDescriptor {

    // properties specific to this registry
    name: ApplicationUnit = 'eefr_reports'

    defaultDocument = {
    //   "id": 1,
    //   "period_start": "2024-01-01",
    //   "period_end": "2024-12-31",
        report_data: '',
        memo: '',
        sections: '',
        // "type_id": 1,
        // status_id: 10,
        uuid: '',
    //   "collections": {}
    }

    // default_row = {
    //     date_paid: '3000-01-01',
    //     obj_id: 0,
    //     // quantity: 1,
    //     // name: '',
    //     // vat_return_percent: 0,
    //     // vat_coef: 0,
    //     // total_vat: 0
    // }

    gridEndpoint = 'eefr_reports_grid'
    docEndpoint = 'eefr_reports'
    selector = {
        endpoint: 'eefr_reports_grid',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'period_end',
        textCol: 'type_name',
        memoCol: 'sections'
    }

    // childrenDescriptors = {
    //     'collections/entries': {
    //         enumeratedField: 'nr',
    //         initialNrValue: 1,
    //         defaultValue: this.default_row,
    //     }
    // }

    public isReadOnly(doc: any): boolean {
        return doc?.status_id != 1;
    }


    getTitle = (doc: any) => {
        const intl = useIntl();
        return doc 
            ? (
                intl.formatDate(doc.period_start, INTL_DATEFORMAT) 
                + ' - ' + intl.formatDate(doc.period_end, INTL_DATEFORMAT)
            ) 
            : '';
    }

    getDetailForm = (docPath: string) => <EEFRReportEdit docPath={docPath} />

    // /**
    //  * @param setup General setup state object
    //  * @returns Filtered setup key-value pairs applicable to this type of document
    //  */
    // public getSetup(setup: KeyValueList): KeyValueList {
    //     // Override this method to apply setup to the document
    //     // console.log('salesinvoices.getSetup', setup)
    //     return {
    //         def_currency_id: setup['DEF.VALUUTAID'] || '0',
    //     }
    // }

    columns: DocGridColumn[] = [
        // { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },

        { name: 'type_name', type: 'string', width: 60 },
        { name: 'period_start', type: 'date', width: 100, label: 'period_start' },
        { name: 'period_end', type: 'date', width: 100, label: 'period_end' },
        { name: 'sections', type: 'string', width: 100 },
        { name: 'status_name', type: 'string', width: 60 },

        { name: 'memo', type: 'string', width: 120 },
        // { name: 'nr', width: 60, align: 'right' },
        // { name: 'gl_date', type: 'date', width: 100, label: 'date' },
        // { name: 'doc', type: 'string' },
        // { name: 'total', type: 'decimal', align: 'right' },
        // { name: 'unbound', type: 'decimal', align: 'right' },
        // { name: 'status_id' },

        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            date: validators.date_required(),
            ledger_type_id: validators.integer_required_positive(),
            has_eefr: Yup.boolean(),
            collections: Yup.object().shape({
                entries: Yup.array().of(
                    Yup.object().shape({
                        account_id: validators.integer_required_positive(),
                        debit: validators.non_negative_decimal2(),
                        credit: validators.non_negative_decimal2(),
                        memo: Yup.string().max(500),
                    })
                )
            })
        });
    }

    // public isReadOnly(doc: any): boolean {
    //     return false; //doc && (doc.status !== 1);
    // }


    // public isLockable(): boolean {
    //     return true;
    // }

    // public isLocked(doc: any): boolean {
    //     return doc && !!doc.status;
    // }

    /** 
     * @brief update document_date when date is changed and document date is undefined
    */
    public on_update_date = async (doc: any, value: any) => {

        const ret = (await getFromCurrentDB(store.getState(), 'fetch_general_ledger_data', { gl_date: value })).data[0];
        console.log('ret', ret)
        if (ret.ret < 1)
            throw new Error(ret.state);
        return {
            ...doc,
            nr: ret.nr,
            fiscal_period_id: ret.period_id
        }
    }
}

export const eefr_reports = new EEFRReportRD();